<template>
	<div>
		<div class="tl-bar">
			<el-row type="flex" justify="space-between" align="middle">
				<el-col :span="2" class="L-text-align-center" onclick="javascript:history.back(-1);">
					<i class="el-icon-arrow-left L-font-bgtext"></i>
				</el-col>
				<el-col :span="20">
					<div class="L-text-align-center L-font-text">
						上机人员详情
					</div>
				</el-col>
				<el-col :span="2"></el-col>
			</el-row>
		</div>
		<div class="tl-container tl-margin-top">
			<div class="L-container-96">
				<el-row :gutter="30">
					<el-col :span="12" class="L-text-align-center">
						<img :src="'data:image/png;base64,'+userInfo.user_certificate_photo" style="width:100px;height:130px">
						<div class="L-font-smtext L-margin-top-10 ">身份证照片</div>
					</el-col>
					<el-col :span="12" class="L-text-align-center">
						<img :src="userInfo.user_ocr_photo" style="width:100px;height:130px">
						<div class="L-font-smtext L-margin-top-10 ">对比照片</div>
					</el-col>
				</el-row>
			</div>
			<div class="L-container-96 L-margin-top-20">
				<div class="tl-title-row">
					<div class="L-font-text" style="padding-right: 10px;">基本信息</div>
				</div>
			</div>

		</div>
		<div class="L-container-96 L-margin-top-20">
			<el-row class="tl-form-line" :gutter="10" type="flex" justify="start" align="middle">
				<el-col :span="4" class="tl-form-item ">姓名:</el-col>
				<el-col :span="8" class="tl-form-content">{{userInfo.user_name}}</el-col>
				<el-col :span="3" class="tl-form-item ">证件:</el-col>
				<el-col :span="9" class="tl-form-content">{{userInfo.user_certificate_code}}</el-col>
			</el-row>
			<el-row class="tl-form-line" :gutter="10" type="flex" justify="start" align="middle">
				
				<el-col :span="4" class="tl-form-item ">性别:</el-col>
				<el-col :span="8" class="tl-form-content">{{userInfo.user_sex}}</el-col>
				<el-col :span="4" class="tl-form-item ">出生日期:</el-col>
				<el-col :span="8" class="tl-form-content">{{userInfo.user_birthday}}</el-col>
			</el-row>
			<el-row class="tl-form-line" :gutter="10" type="flex" justify="start" align="middle">
				
				
				<el-col :span="4" class="tl-form-item ">上机时间:</el-col>
				<el-col :span="8" class="tl-form-content">{{userInfo.computer_onlinetime}}</el-col>
				<el-col :span="5" class="tl-form-item ">上机IP地址:</el-col>
				<el-col :span="7" class="tl-form-content">{{userInfo.computer_ip}}</el-col>
			</el-row>
			<!-- <el-row class="tl-form-line" :gutter="10" type="flex" justify="start" align="middle">
				
			</el-row> -->
		</div>
		<div class="L-margin-top-20 L-container-96">
			<el-button style="width: 100%;" type="primary" onclick="javascript:history.back(-1);">返回</el-button>
		</div>

	</div>
</template>

<script>
	export default{
		data(){
			return{
				userInfo:"",
				
			}
		},
		mounted(){
			this.$axios({
				method:"get",
				url:"/ctid/PhoneService/findZxRyxx",
				params:{
					user_certificate_code:this.$route.query.user_certificate_code,
				}
			}).then(res=>{
				this.userInfo = res.data.data;
				console.log(res.data.data);
			})
		}
		
	}

</script>

<style>
	.tl-color {
		color: #409EFF
	}

	.L-container-96 {
		width: 96%;
		margin: 0px auto;
	}

	.L-container-90 {
		width: 90%;
		margin: 0px auto;
	}

	.L-container-80 {
		width: 80%;
		margin: 0px auto;
	}

	.L-flex-row {
		display: flex;
		align-items: center;
		justify-content: flex-start;
	}


	/* L-style */
	.L-row-space {
		width: 100%;
		height: 20px;
	}

	.L-row-nowrap {
		text-overflow: ellipsis;
		white-space: nowrap;
		overflow: hidden;
	}

	.L-font-h1 {
		font-size: 40px;
	}

	.L-font-h2 {
		font-size: 35px;
	}

	.L-font-h3 {
		font-size: 30px;
	}

	.L-font-h4 {
		font-size: 25px;
	}

	.L-font-h5 {
		font-size: 20px;
	}

	.L-font-bgtext {
		font-size: 16px;
	}

	.L-font-text {
		font-size: 14px;
	}

	.L-font-smtext {
		font-size: 12px;
	}

	/* color */
	.L-font-color-gray {
		color: #95A5A6
	}

	.L-font-color-666 {
		color: #666;
	}

	.L-font-color-777 {
		color: #777;
	}

	.L-font-color-888 {
		color: #888;
	}

	.L-font-color-999 {
		color: #999;
	}

	.L-font-color-aaa {
		color: #aaa;
	}

	.L-font-color-bbb {
		color: #bbb;
	}

	.L-font-color-ccc {
		color: #ccc;
	}

	.L-font-color-ddd {
		color: #ddd;
	}

	.L-font-color-red {
		color: #F56C6C
	}

	.L-font-color-white {
		color: #fff;
	}

	/*  */
	.L-font-bold {
		font-weight: bold;
	}

	/* text-align */

	.L-text-align-center {
		text-align: center;
	}

	/*  */
	.L-row-90 {
		margin: 0 auto;
		width: 90%;
	}

	/* margin */
	.L-margin-top-10 {
		margin-top: 10px;
	}

	.L-margin-top-20 {
		margin-top: 20px;
	}

	.L-margin-top-30 {
		margin-top: 30px;
	}

	.L-margin-top-40 {
		margin-top: 40px;
	}

	.L-margin-top-50 {
		margin-top: 50px;
	}

	.L-margin-top-60 {
		margin-top: 60px;
	}

	.L-margin-top-70 {
		margin-top: 70px;
	}

	.L-margin-right-5 {
		margin-right: 5px;
	}

	.tl-margin-top {
		margin-top: 50px;
	}
	
	.tl-form-item {
		font-size: 12px;
		color: #777777
	}

	.tl-form-content {
		font-size: 12px;

	}

	.tl-form-line {
		border-bottom: 1px dashed #eee;
		padding: 10px 0;
		margin-top: 5px
	}
</style>
